import React from "react";
import style from "./CommissionsRulesTable.module.css";
import useScreenWidth from "@Hooks/useScreenWidth/useScreenWidth";
import { useNavigate } from "react-router-dom";
import { HiCurrencyDollar } from "react-icons/hi2";
import { CommonArrowRightIcon } from "@Models/icons";
// @ts-ignore
import { Icon, IconWrapper } from "@viuti/recursos";
import { ICommission } from "@Models/interfaces/commissions";
import { openDeleteCommissionModal } from "./functions/deleteCommission";
import { convertDateFormat } from "@Utilities/convertDateFormat";
import { useAppDispatch } from "@Hooks/store";
import { HiMinusCircle } from "react-icons/hi";

const CommissionsRulesTable = ({ items }: { items: ICommission[] }) => {
  const dispatch = useAppDispatch();
  const screenWidth = useScreenWidth();
  const navigate = useNavigate();

  const goToEditCommission = (id: number) => {
    navigate(`/editar-regla/${id}`);
  };

  return (
    <>
      {items.map((item: ICommission) => {
        return (
          <button
            key={item.id}
            className={style.row_commission}
            onClick={() => goToEditCommission(item.id)}
            disabled={!item.isActive}
          >
            <div className={style.row_info}>
              <IconWrapper icon={HiCurrencyDollar} size={22} />
              <h3 className={style.row__info__name}>{item.name}</h3>
              <p
                className={`${
                  item.isActive
                    ? style.activeCommission
                    : style.inactiveCommission
                }`}
              >
                {item.isActive ? "Activa" : "Inactiva"}
              </p>
              <p>
                Creado: <b>{convertDateFormat(item.createdDate)}</b>
              </p>
              <p>
                Por: <b>{item.employee}</b>
              </p>
            </div>
            <span className={style.row_actions}>
              {screenWidth > 900 && (
                <div className={style.icon__edit}>
                  <Icon
                    path={CommonArrowRightIcon}
                    color="#45348E"
                    size={16}
                    alt="Editar"
                  />
                </div>
              )}
              {item.isActive && (
                <button
                  onClick={(e) =>
                    openDeleteCommissionModal(e, item.id, dispatch, screenWidth)
                  }
                  className={style.icon__delete}
                >
                  <IconWrapper icon={HiMinusCircle} size={18} color="#939393" />
                </button>
              )}
            </span>
          </button>
        );
      })}
    </>
  );
};

export default CommissionsRulesTable;
