import axios from "axios";
const GW_URL = `https://gw-qa.prikly.io`;

export const commissionsBaseUrl = `${GW_URL}/commissions/v1`;
export const servicesBaseUrl = `${GW_URL}/servicios/v1`;
export const productsBaseUrl = `${GW_URL}/almacen/v1`;
export const employeesBaseUrl = `${GW_URL}/empleados/v1`;
export const tokenLocal = localStorage.getItem("access_token");

export const config = {
  headers: {
    Authorization: `Bearer ${tokenLocal}`,
  },
};

export interface ApiResponse {
  isSuccess: boolean;
  status: number;
  data: any;
  message: string;
}

export const handleSuccessfullResponse = (
  response: any,
  successMessage: string,
): ApiResponse => ({
  isSuccess: true,
  status: response?.status || 200,
  data: response.data.data,
  message: response?.data?.message || successMessage,
});

export const handleApiError = (
  error: any,
  errorMessage: string,
): ApiResponse => {
  return {
    isSuccess: false,
    status: error?.response?.status || 500,
    data: null,
    message: error?.response?.data?.message || errorMessage,
  };
};

export const getData = async (
  url: string,
  successMessage: string,
  errorMessage: string,
) => {
  try {
    const response = await axios.get(url, config);
    return handleSuccessfullResponse(response, successMessage);
  } catch (error) {
    return handleApiError(error, errorMessage);
  }
};

export const postData = async (
  url: string,
  data: any,
  successMessage: string,
  errorMessage: string,
) => {
  try {
    const response = await axios.post(url, data, config);
    return handleSuccessfullResponse(response, successMessage);
  } catch (error) {
    return handleApiError(error, errorMessage);
  }
};

export const putData = async (
  url: string,
  data: any,
  successMessage: string,
  errorMessage: string,
) => {
  try {
    const response = await axios.put(url, data, config);
    return handleSuccessfullResponse(response, successMessage);
  } catch (error) {
    return handleApiError(error, errorMessage);
  }
};

export const deleteData = async (
  url: string,
  successMessage: string,
  errorMessage: string,
) => {
  try {
    const response = await axios.delete(url, config);
    return handleSuccessfullResponse(response, successMessage);
  } catch (error) {
    return handleApiError(error, errorMessage);
  }
};
